import { Injectable } from '@angular/core';
import { DocumentApiService, Library, LibraryService } from '@core/services';
import { DefaultDocuments, DocumentTypes } from '@shared/reference';
import { Observable, catchError, forkJoin, map, mergeMap, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '@env';

export interface Template {
  id: string;
  name: string;
  workspaceAdminLimit: number;
  workspaceStandardLimit: number;
}

export interface DocumentItem {
  id: string;
  templateIds: string[];
  type: DocumentTypes;
  document: any;
  isCommon: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PopulateService {
  private isInitialised = false;
  private documentCache: DocumentItem[] = [];

  constructor(
    private documentApiService: DocumentApiService,
    private library: LibraryService,
  ) { }

  init(): void {
    if (!this.isInitialised) {
      forkJoin([
        this.loadFromLibrary(),
      ]).subscribe(() => {
        this.isInitialised = true;
      });
    }
  }

  initAsync(): Observable<Boolean> {
    if (!this.isInitialised) {
      return forkJoin([
        this.loadFromLibrary(),
      ]).pipe(
        map(() => {
          this.isInitialised = true;
          return true;
        }),
        catchError(e => {
          console.error(e);
          return of(false);
        })
      );
    }
    return of(true);
  }

  getDefaultTemplateId(): string {
    return 'smartAuditV2';
  }


  getTemplates(): Template[] {
    const templates = [
      {
        id: 'smartAuditV2',
        name: 'Plus Training',
        workspaceAdminLimit: 1,
        workspaceStandardLimit: 30
      },
      {
        id: 'integration',
        name: 'Integrations'
      },
      {
        id: 'starter',
        name: 'Starter',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'takeaway',
        name: 'Plus Basic',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
      {
        id: 'plusPremium',
        name: 'Plus Premium',
        workspaceAdminLimit: 10,
        workspaceStandardLimit: 0
      },
    ] as Template[];

    if (!environment.production) {
      templates.push({
        id: 'automation',
        name: 'Automation',
        workspaceAdminLimit: 1,
        workspaceStandardLimit: 30
      });
    }

    templates.sort((a, b) => a.name.localeCompare(b.name));
    return templates;
  }

  loadFromLibrary(): Observable<Boolean> {
    return forkJoin([
      this.library.getItem(Library.DefaultRecordType), // 0
      this.library.getItem(Library.DefaultRecordTypeTranslation), // 1
      this.library.getItem(Library.GlobalTranslation), // 2
      this.library.getItem(Library.LocationRecordType), // 3
      this.library.getItem(Library.LocationTranslation), // 4
      this.library.getItem(Library.PersonRecordType), // 5
      this.library.getItem(Library.PersonTranslation), // 6
      this.library.getItem(Library.RecordTypeTranslation), // 7
      this.library.getItem(Library.StarterRecordRecordType), // 8
      this.library.getItem(Library.StarterRecordTranslation), // 9
      this.library.getItem(Library.StarterReferenceData), // 10
      this.library.getItem(Library.StarterReferenceDataTranslation), // 11
      this.library.getItem(Library.StarterHomePageDocument), // 12
      this.library.getItem(Library.StarterHomePageTranslation), // 13
      this.library.getItem(Library.CmpOrganisationRecordType), // 14
      this.library.getItem(Library.CmpOrganisationTranslation), // 15
      this.library.getItem(Library.CmpPlatformMemberRecordType), // 16
      this.library.getItem(Library.CmpPlatformMemberTranslation), // 17
      this.library.getItem(Library.CmpPolicyRecordType), // 18
      this.library.getItem(Library.CmpPolicyTranslation), // 19
      this.library.getItem(Library.CmpWorkspaceRecordType), // 20
      this.library.getItem(Library.CmpWorkspaceTranslation), // 21
      this.library.getItem(Library.CmpReferenceData), // 22
      this.library.getItem(Library.CmpReferenceDataTranslation), // 23
      this.library.getItem(Library.CmpHomePageDocument), // 24
      this.library.getItem(Library.CmpHomePageTranslation), // 25
      this.library.getItem(Library.CmpWorkspaceDataDocument), // 26
      this.library.getItem(Library.StarterWorkspaceDataDocument), // 27
      this.library.getItem(Library.UserDataDocument), // 28
      this.library.getItem(Library.DefaultTermsOfUseDocument), // 29
      this.library.getItem(Library.EmailTemplateDocument), //30

      this.library.getItem(Library.SmartAuditV2GlobalTranslation), // 31
      this.library.getItem(Library.SmartAuditV2LocationRecordType), // 32
      this.library.getItem(Library.SmartAuditV2LocationTranslation), // 33
      this.library.getItem(Library.SmartAuditV2PersonRecordType), // 34
      this.library.getItem(Library.SmartAuditV2PersonTranslation), // 35
      this.library.getItem(Library.SmartAuditV2RecordTypeTranslation), // 36
      this.library.getItem(Library.SmartAuditV2AuditRecordType), // 37
      this.library.getItem(Library.SmartAuditV2AuditTranslation), // 38
      this.library.getItem(Library.SmartAuditV2ChecklistRecordType), // 39
      this.library.getItem(Library.SmartAuditV2ChecklistTranslation), // 40
      this.library.getItem(Library.SmartAuditV2FindingRecordType), // 41
      this.library.getItem(Library.SmartAuditV2FindingTranslation), // 42
      this.library.getItem(Library.SmartAuditV2ReferenceData), // 43
      this.library.getItem(Library.SmartAuditV2ReferenceDataTranslation), // 44
      this.library.getItem(Library.SmartAuditV2HomePageDocument), // 45
      this.library.getItem(Library.SmartAuditV2HomePageTranslation), // 46
      this.library.getItem(Library.SmartAuditV2WorkspaceDataDocument), // 47

      this.library.getItem(Library.AutomationGlobalTranslation), // 48
      this.library.getItem(Library.AutomationLocationRecordType), // 49
      this.library.getItem(Library.AutomationLocationTranslation), // 50
      this.library.getItem(Library.AutomationPersonRecordType), // 51
      this.library.getItem(Library.AutomationPersonTranslation), // 52
      this.library.getItem(Library.AutomationRecordTypeTranslation), // 53
      this.library.getItem(Library.AutomationActionRecordType), // 54
      this.library.getItem(Library.AutomationActionTranslation), // 55
      this.library.getItem(Library.AutomationAuditRecordType), // 56
      this.library.getItem(Library.AutomationAuditTranslation), // 57
      this.library.getItem(Library.AutomationChecklistRecordType), // 58
      this.library.getItem(Library.AutomationChecklistTranslation), // 59
      this.library.getItem(Library.AutomationFindingRecordType), // 60
      this.library.getItem(Library.AutomationFindingTranslation), // 61
      this.library.getItem(Library.AutomationReferenceData), // 62
      this.library.getItem(Library.AutomationReferenceDataTranslation), // 63
      this.library.getItem(Library.AutomationHomePageDocument), // 64
      this.library.getItem(Library.AutomationHomePageTranslation), // 65
      this.library.getItem(Library.AutomationWorkspaceDataDocument), // 66

      this.library.getItem(Library.TakeawayGlobalTranslation), // 67
      this.library.getItem(Library.TakeawayLocationRecordType), // 68
      this.library.getItem(Library.TakeawayLocationTranslation), // 69
      this.library.getItem(Library.TakeawayPersonRecordType), // 70
      this.library.getItem(Library.TakeawayPersonTranslation), // 71
      this.library.getItem(Library.TakeawayRecordTypeTranslation), // 72
      this.library.getItem(Library.TakeawayAuditRecordType), // 73
      this.library.getItem(Library.TakeawayAuditTranslation), // 74
      this.library.getItem(Library.TakeawayChecklistRecordType), // 75
      this.library.getItem(Library.TakeawayChecklistTranslation), // 76
      this.library.getItem(Library.TakeawayFindingRecordType), // 77
      this.library.getItem(Library.TakeawayFindingTranslation), // 78
      this.library.getItem(Library.TakeawayReferenceData), // 79
      this.library.getItem(Library.TakeawayReferenceDataTranslation), // 80
      this.library.getItem(Library.TakeawayHomePageDocument), // 81
      this.library.getItem(Library.TakeawayHomePageTranslation), // 82
      this.library.getItem(Library.TakeawayWorkspaceDataDocument), // 83
      this.library.getItem(Library.TakeawayTermsOfUseDocument), // 84

      this.library.getItem(Library.PlusPremiumGlobalTranslation), // 85
      this.library.getItem(Library.PlusPremiumLocationRecordType), // 86
      this.library.getItem(Library.PlusPremiumLocationTranslation), // 87
      this.library.getItem(Library.PlusPremiumPersonRecordType), // 88
      this.library.getItem(Library.PlusPremiumPersonTranslation), // 89
      this.library.getItem(Library.PlusPremiumRecordTypeTranslation), // 90
      this.library.getItem(Library.PlusPremiumActionRecordType), // 91
      this.library.getItem(Library.PlusPremiumActionTranslation), // 92
      this.library.getItem(Library.PlusPremiumAuditRecordType), // 93
      this.library.getItem(Library.PlusPremiumAuditTranslation), // 94
      this.library.getItem(Library.PlusPremiumChecklistRecordType), // 95
      this.library.getItem(Library.PlusPremiumChecklistTranslation), // 96
      this.library.getItem(Library.PlusPremiumFindingRecordType), // 97
      this.library.getItem(Library.PlusPremiumFindingTranslation), // 98
      this.library.getItem(Library.PlusPremiumReferenceData), // 99
      this.library.getItem(Library.PlusPremiumReferenceDataTranslation), // 100
      this.library.getItem(Library.PlusPremiumHomePageDocument), // 101
      this.library.getItem(Library.PlusPremiumHomePageTranslation), // 102
      this.library.getItem(Library.PlusPremiumWorkspaceDataDocument), // 103
      this.library.getItem(Library.PlusPremiumTermsOfUseDocument), // 104

      this.library.getItem(Library.IntegrationFindingRecordType), // 105
      this.library.getItem(Library.IntegrationFindingTranslation), // 106
      this.library.getItem(Library.IntegrationReferenceDataDocument), // 107
      this.library.getItem(Library.IntegrationReferenceDataTranslation), // 108
      this.library.getItem(Library.IntegrationRecordType), // 109
      this.library.getItem(Library.IntegrationTranslation), // 110
      this.library.getItem(Library.IntegrationWorkspaceRecordType), // 111
      this.library.getItem(Library.IntegrationWorkspaceDataDocument), // 112
      this.library.getItem(Library.IntegrationWorkspaceTranslation), // 113
      this.library.getItem(Library.ServiceAccountRecordType), // 114
      this.library.getItem(Library.ServiceAccountTranslation), // 115
    ]).pipe(
      map(documents => {
        this.documentCache = [
          {
            id: DefaultDocuments.defaultRecordType,
            templateIds: [],
            type: DocumentTypes.recordType,
            isCommon: true,
            document: documents[0]
          },
          {
            id: DefaultDocuments.defaultTranslation,
            templateIds: [],
            type: DocumentTypes.translation,
            isCommon: true,
            document: documents[1]
          },
          {
            id: DefaultDocuments.globalTranslation,
            templateIds: ['starter', 'cmp', 'integration'],
            type: DocumentTypes.translation,
            isCommon: true,
            document: documents[2]
          },
          {
            id: DefaultDocuments.locationRecordType,
            templateIds: ['starter', 'cmp', 'integration'],
            type: DocumentTypes.recordType,
            isCommon: true,
            document: documents[3]
          },
          {
            id: DefaultDocuments.locationTranslation,
            templateIds: ['starter', 'cmp', 'integration'],
            type: DocumentTypes.translation,
            isCommon: true,
            document: documents[4]
          },
          {
            id: DefaultDocuments.personRecordType,
            templateIds: ['starter', 'cmp', 'integration'],
            type: DocumentTypes.recordType,
            isCommon: true,
            document: documents[5]
          },
          {
            id: DefaultDocuments.personTranslation,
            templateIds: ['starter', 'cmp', 'integration'],
            type: DocumentTypes.translation,
            isCommon: true,
            document: documents[6]
          },
          {
            id: DefaultDocuments.recordTypeTranslation,
            templateIds: ['starter', 'cmp', 'integration'],
            type: DocumentTypes.translation,
            isCommon: true,
            document: documents[7]
          },
          {
            id: DefaultDocuments.starterRecordRecordType,
            templateIds: ['starter'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[8]
          },
          {
            id: DefaultDocuments.starterRecordTranslation,
            templateIds: ['starter'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[9]
          },
          {
            id: DefaultDocuments.starterReferenceData,
            templateIds: ['starter'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[10]
          },
          {
            id: DefaultDocuments.starterReferenceDataTranslation,
            templateIds: ['starter'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[11]
          },
          {
            id: DefaultDocuments.starterHomePageDocument,
            templateIds: ['starter'],
            type: DocumentTypes.dashboard,
            isCommon: false,
            document: documents[12]
          },
          {
            id: DefaultDocuments.starterHomePageTranslation,
            templateIds: ['starter'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[13]
          },
          {
            id: DefaultDocuments.cmpOrganisationRecordType,
            templateIds: ['cmp'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[14]
          },
          {
            id: DefaultDocuments.cmpOrganisationTranslation,
            templateIds: ['cmp'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[15]
          },
          {
            id: DefaultDocuments.cmpPlatformMemberRecordType,
            templateIds: ['cmp'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[16]
          },
          {
            id: DefaultDocuments.cmpPlatformMemberTranslation,
            templateIds: ['cmp'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[17]
          },
          {
            id: DefaultDocuments.cmpPolicyRecordType,
            templateIds: ['cmp'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[18]
          },
          {
            id: DefaultDocuments.cmpPolicyTranslation,
            templateIds: ['cmp'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[19]
          },
          {
            id: DefaultDocuments.cmpWorkspaceRecordType,
            templateIds: ['cmp'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[20]
          },
          {
            id: DefaultDocuments.cmpWorkspaceTranslation,
            templateIds: ['cmp'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[21]
          },
          {
            id: DefaultDocuments.cmpReferenceDataDocument,
            templateIds: ['cmp'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[22]
          },
          {
            id: DefaultDocuments.cmpReferenceDataTranslation,
            templateIds: ['cmp'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[23]
          },
          {
            id: DefaultDocuments.cmpHomePageDocument,
            templateIds: ['cmp'],
            type: DocumentTypes.dashboard,
            isCommon: false,
            document: documents[24]
          },
          {
            id: DefaultDocuments.cmpHomePageTranslation,
            templateIds: ['cmp'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[25]
          },
          {
            id: DefaultDocuments.cmpWorkspaceDataDocument,
            templateIds: ['cmp'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[26]
          },
          {
            id: DefaultDocuments.starterWorkspaceDataDocument,
            templateIds: ['starter'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[27]
          },
          {
            id: DefaultDocuments.userDataDocument,
            templateIds: ['starter', 'cmp', 'smartAuditV2', 'automation', 'takeaway'],
            type: DocumentTypes.userData,
            isCommon: true,
            document: documents[28]
          },
          {
            id: DefaultDocuments.termsOfUseDocument,
            templateIds: ['starter', 'cmp'],
            type: DocumentTypes.termsOfUse,
            isCommon: true,
            document: documents[29]
          },
          {
            id: DefaultDocuments.emailTemplate,
            templateIds: [
              'starter',
              'cmp',
              'smartAuditV2',
              'automation',
              'takeaway',
              'plusPremium',
            ],
            type: DocumentTypes.emailTemplate,
            isCommon: true,
            document: documents[30]
          },
          {
            id: DefaultDocuments.smartAuditV2globalTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[31]
          },
          {
            id: DefaultDocuments.smartAuditV2locationRecordType,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[32]
          },
          {
            id: DefaultDocuments.smartAuditV2locationTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[33]
          },
          {
            id: DefaultDocuments.smartAuditV2personRecordType,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[34]
          },
          {
            id: DefaultDocuments.smartAuditV2personTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[35]
          },
          {
            id: DefaultDocuments.smartAuditV2recordTypeTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[36]
          },
          {
            id: DefaultDocuments.smartAuditV2AuditRecordType,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[37]
          },
          {
            id: DefaultDocuments.smartAuditV2AuditTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[38]
          },
          {
            id: DefaultDocuments.smartAuditV2ChecklistRecordType,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[39]
          },
          {
            id: DefaultDocuments.smartAuditV2ChecklistTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[40]
          },
          {
            id: DefaultDocuments.smartAuditV2FindingRecordType,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[41]
          },
          {
            id: DefaultDocuments.smartAuditV2FindingTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[42]
          },
          {
            id: DefaultDocuments.smartAuditV2ReferenceDataDocument,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[43]
          },
          {
            id: DefaultDocuments.smartAuditV2ReferenceDataTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[44]
          },
          {
            id: DefaultDocuments.smartAuditV2HomePageDocument,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.dashboard,
            isCommon: false,
            document: documents[45]
          },
          {
            id: DefaultDocuments.smartAuditV2HomePageTranslation,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[46]
          },
          {
            id: DefaultDocuments.smartAuditV2WorkspaceDataDocument,
            templateIds: ['smartAuditV2'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[47]
          },
          {
            id: DefaultDocuments.automationGlobalTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[48]
          },
          {
            id: DefaultDocuments.automationLocationRecordType,
            templateIds: ['automation'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[49]
          },
          {
            id: DefaultDocuments.automationLocationTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[50]
          },
          {
            id: DefaultDocuments.automationPersonRecordType,
            templateIds: ['automation'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[51]
          },
          {
            id: DefaultDocuments.automationPersonTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[52]
          },
          {
            id: DefaultDocuments.automationRecordTypeTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[53]
          },
          {
            id: DefaultDocuments.automationActionRecordType,
            templateIds: ['automation'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[54]
          },
          {
            id: DefaultDocuments.automationActionTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[55]
          },
          {
            id: DefaultDocuments.automationAuditRecordType,
            templateIds: ['automation'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[56]
          },
          {
            id: DefaultDocuments.automationAuditTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[57]
          },
          {
            id: DefaultDocuments.automationChecklistRecordType,
            templateIds: ['automation'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[58]
          },
          {
            id: DefaultDocuments.automationChecklistTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[59]
          },
          {
            id: DefaultDocuments.automationFindingRecordType,
            templateIds: ['automation'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[60]
          },
          {
            id: DefaultDocuments.automationFindingTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[61]
          },
          {
            id: DefaultDocuments.automationReferenceDataDocument,
            templateIds: ['automation'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[62]
          },
          {
            id: DefaultDocuments.automationReferenceDataTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[63]
          },
          {
            id: DefaultDocuments.automationHomePageDocument,
            templateIds: ['automation'],
            type: DocumentTypes.dashboard,
            isCommon: false,
            document: documents[64]
          },
          {
            id: DefaultDocuments.automationHomePageTranslation,
            templateIds: ['automation'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[65]
          },
          {
            id: DefaultDocuments.automationWorkspaceDataDocument,
            templateIds: ['automation'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[66]
          },
          {
            id: DefaultDocuments.takeawayGlobalTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[67]
          },
          {
            id: DefaultDocuments.takeawayLocationRecordType,
            templateIds: ['takeaway'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[68]
          },
          {
            id: DefaultDocuments.takeawayLocationTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[69]
          },
          {
            id: DefaultDocuments.takeawayPersonRecordType,
            templateIds: ['takeaway'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[70]
          },
          {
            id: DefaultDocuments.takeawayPersonTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[71]
          },
          {
            id: DefaultDocuments.takeawayRecordTypeTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[72]
          },
          {
            id: DefaultDocuments.takeawayAuditRecordType,
            templateIds: ['takeaway'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[73]
          },
          {
            id: DefaultDocuments.takeawayAuditTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[74]
          },
          {
            id: DefaultDocuments.takeawayChecklistRecordType,
            templateIds: ['takeaway'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[75]
          },
          {
            id: DefaultDocuments.takeawayChecklistTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[76]
          },
          {
            id: DefaultDocuments.takeawayFindingRecordType,
            templateIds: ['takeaway'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[77]
          },
          {
            id: DefaultDocuments.takeawayFindingTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[78]
          },
          {
            id: DefaultDocuments.takeawayReferenceDataDocument,
            templateIds: ['takeaway'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[79]
          },
          {
            id: DefaultDocuments.takeawayReferenceDataTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[80]
          },
          {
            id: DefaultDocuments.takeawayHomePageDocument,
            templateIds: ['takeaway'],
            type: DocumentTypes.dashboard,
            isCommon: false,
            document: documents[81]
          },
          {
            id: DefaultDocuments.takeawayHomePageTranslation,
            templateIds: ['takeaway'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[82]
          },
          {
            id: DefaultDocuments.takeawayWorkspaceDataDocument,
            templateIds: ['takeaway'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[83]
          },
          {
            id: DefaultDocuments.takeawayTermsOfUseDocument,
            templateIds: ['takeaway'],
            type: DocumentTypes.termsOfUse,
            isCommon: false,
            document: documents[84]
          },
          {
            id: DefaultDocuments.plusPremiumGlobalTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[85]
          },
          {
            id: DefaultDocuments.plusPremiumLocationRecordType,
            templateIds: ['plusPremium'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[86]
          },
          {
            id: DefaultDocuments.plusPremiumLocationTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[87]
          },
          {
            id: DefaultDocuments.plusPremiumPersonRecordType,
            templateIds: ['plusPremium'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[88]
          },
          {
            id: DefaultDocuments.plusPremiumPersonTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[89]
          },
          {
            id: DefaultDocuments.plusPremiumRecordTypeTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[90]
          },
          {
            id: DefaultDocuments.plusPremiumActionRecordType,
            templateIds: ['plusPremium'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[91]
          },
          {
            id: DefaultDocuments.plusPremiumActionTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[92]
          },
          {
            id: DefaultDocuments.plusPremiumAuditRecordType,
            templateIds: ['plusPremium'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[93]
          },
          {
            id: DefaultDocuments.plusPremiumAuditTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[94]
          },
          {
            id: DefaultDocuments.plusPremiumChecklistRecordType,
            templateIds: ['plusPremium'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[95]
          },
          {
            id: DefaultDocuments.plusPremiumChecklistTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[96]
          },
          {
            id: DefaultDocuments.plusPremiumFindingRecordType,
            templateIds: ['plusPremium'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[97]
          },
          {
            id: DefaultDocuments.plusPremiumFindingTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[98]
          },
          {
            id: DefaultDocuments.plusPremiumReferenceDataDocument,
            templateIds: ['plusPremium'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[99]
          },
          {
            id: DefaultDocuments.plusPremiumReferenceDataTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[100]
          },
          {
            id: DefaultDocuments.plusPremiumHomePageDocument,
            templateIds: ['plusPremium'],
            type: DocumentTypes.dashboard,
            isCommon: false,
            document: documents[101]
          },
          {
            id: DefaultDocuments.plusPremiumHomePageTranslation,
            templateIds: ['plusPremium'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[102]
          },
          {
            id: DefaultDocuments.plusPremiumWorkspaceDataDocument,
            templateIds: ['plusPremium'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[103]
          },
          {
            id: DefaultDocuments.plusPremiumTermsOfUseDocument,
            templateIds: ['plusPremium'],
            type: DocumentTypes.termsOfUse,
            isCommon: false,
            document: documents[104]
          },
          {
            id: DefaultDocuments.integrationFindingRecordType,
            templateIds: ['integration'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[105]
          },
          {
            id: DefaultDocuments.integrationFindingTranslation,
            templateIds: ['integration'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[106]
          },
          {
            id: DefaultDocuments.integrationReferenceDataDocument,
            templateIds: ['integration'],
            type: DocumentTypes.referenceData,
            isCommon: false,
            document: documents[107]
          },
          {
            id: DefaultDocuments.integrationReferenceDataTranslation,
            templateIds: ['integration'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[108]
          },
          {
            id: DefaultDocuments.integrationRecordType,
            templateIds: ['integration'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[109]
          },
          {
            id: DefaultDocuments.integrationTranslation,
            templateIds: ['integration'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[110]
          },
          {
            id: DefaultDocuments.integrationWorkspaceRecordType,
            templateIds: ['integration'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[111]
          },
          {
            id: DefaultDocuments.integrationWorkspaceDataDocument,
            templateIds: ['integration'],
            type: DocumentTypes.workspaceData,
            isCommon: false,
            document: documents[112]
          },
          {
            id: DefaultDocuments.integrationWorkspaceTranslation,
            templateIds: ['integration'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[113]
          },
          {
            id: DefaultDocuments.serviceAccountRecordType,
            templateIds: ['starter', 'smartAuditV2', 'cmp', 'integration'],
            type: DocumentTypes.recordType,
            isCommon: false,
            document: documents[114]
          },
          {
            id: DefaultDocuments.serviceAccountTranslation,
            templateIds: ['starter', 'smartAuditV2', 'cmp', 'integration'],
            type: DocumentTypes.translation,
            isCommon: false,
            document: documents[115]
          },

        ] as DocumentItem[];
        return true;
      }),
      catchError(e => {
        console.error(e);
        return of(false);
      })
    );
  }

  getDefaultDocument(id: string): any {
    const item = this.documentCache.find(item => item.id === id);
    if (item) {
      return item.document;
    }
    return undefined;
  }

  getDocumentItemsForTemplate(templateId: string): DocumentItem[] {
    return this.documentCache.filter(item => item.templateIds.includes(templateId));
  }

  populate(workspaceId: string, templateId: string, workspaceDisplayName: string):
    Observable<Boolean> {

    if (!this.isInitialised) {
      return of(false);
    }

    const createCalls: Observable<any>[] = [];

    this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.type === DocumentTypes.recordType)
      .forEach(item => {
        createCalls.push(this.documentApiService.createRecordType(item.document, workspaceId));
      });

    this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.type !== DocumentTypes.recordType
        && item.type !== DocumentTypes.userData
        && item.type !== DocumentTypes.workspaceData)
      .forEach(item => {
        createCalls.push(this.documentApiService.createDocument({
          documentId: uuidv4(),
          documentType: item.type,
          content: item.document,
        }, workspaceId));
      });

    this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.type === DocumentTypes.userData)
      .forEach(item => {
        createCalls.push(this.documentApiService.createDocument({
          documentId: uuidv4(),
          documentType: item.type,
          content: item.document,
          isPrivate: false
        }, workspaceId));
      });

    this.documentCache
      .filter(item => item.templateIds.includes(templateId)
        && item.type === DocumentTypes.workspaceData)
      .forEach(item => {
        var content = item.document;
        if (content?.info !== null && content?.info !== undefined)
        {
          // Update workspaceUrl with environment variable
          // This will use in email task to set application's initial url
          item.document.info.workspaceUrl = environment.workspaceUrl;
          // Including workspace's display name in workspaceData document
          item.document.info.workspaceDisplayName = workspaceDisplayName;
        }
        createCalls.push(this.documentApiService.createDocument({
          documentId: uuidv4(),
          documentType: item.type,
          content: item.document,
          isPrivate: false
        }, workspaceId));
      });

    return forkJoin(createCalls).pipe(
      mergeMap(() => {
        console.log('Populate complete');
        return of(true);
      }),
      catchError(e => {
        console.error(e);
        return of(false);
      }));
  }

  verifyAndRetryPopulate(workspaceId: string, templateId: string, workspaceDisplayName: string):
    Observable<boolean> {
    const documentItemsForTemplate = this.documentCache
      .filter(item => item.templateIds.includes(templateId));

    return forkJoin([
      this.documentApiService.getAllRecordTypes(workspaceId),
      this.documentApiService.getAllDocuments(workspaceId)
    ]).pipe(
      mergeMap(result => {
        const [recordTypes, documents] = result;

        const createCalls: Observable<any>[] = [];

        // Loop through documentItemsForTemplate to verify and create documents
        documentItemsForTemplate.forEach(item => {

          if (item.type === DocumentTypes.recordType) {
            // Check if all the recordTypes exist
            const recordTypeExists = recordTypes.
              some(type => type.recordType === item.document.recordType);

            if (!recordTypeExists) {
              // Create the recordtype if it doesn't exist
              createCalls.push
                (this.documentApiService.createRecordType(item.document, workspaceId));
            }
          }
          else {
            // Check if the document type exists in documents and has content
            const documentExists = documents.some(doc => doc.documentType === item.type
              && doc.content);

            if (!documentExists) {
              // Create the document if it doesn't exist
              if (item.type === DocumentTypes.userData) {
                createCalls.push(this.documentApiService.createDocument({
                  documentId: uuidv4(),
                  documentType: item.type,
                  content: item.document,
                  isPrivate: false
                }, workspaceId));
              }
              else if (item.type === DocumentTypes.workspaceData) {
                item.document.info.workspaceDisplayName = workspaceDisplayName;
                createCalls.push(this.documentApiService.createDocument({
                  documentId: uuidv4(),
                  documentType: item.type,
                  content: item.document,
                  isPrivate: false
                }, workspaceId));
              }
              else {
                createCalls.push(this.documentApiService.createDocument({
                  documentId: uuidv4(),
                  documentType: item.type,
                  content: item.document,
                }, workspaceId));
              }
            }
          }
        });

        return forkJoin(createCalls).pipe(
          mergeMap(() => {
            console.log('Populate complete');
            return of(true);
          }),
          catchError(e => {
            console.error(e);
            return of(false);
          }));
      }),
      catchError(e => {
        console.log(`Cannot get documents from workspace: ${e.error.detail}`);
        return of(false);
      })
    );
  }

}
